import React, {useState, useEffect, useRef} from 'react'
import gsap from 'gsap'
import Hero from '../components/global/Hero'
import IntroSectionReuseable from '../components/reuseable/IntroSectionReuseable';
import CTASection from '../components/global/CTASection'
import { graphql } from 'gatsby'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import { Styling } from '../styles/exDisplayArchive/styled.js'
import Pagination from '../components/reuseable/Pagination';
import Texture from '../components/animation/Texture'
import { Helmet } from 'react-helmet';
import {ImInfo} from 'react-icons/im'
import parse from 'html-react-parser'
import ContextConsumer from "../layouts/Context"
import { navigate } from 'gatsby';
import {BsChevronDown} from 'react-icons/bs'
import { Link } from 'gatsby';



export default function ExDisplayArchive(props) {
    const heroImage = props.pageContext.page.customHeroImage.heroImage
    const title = props.pageContext.page.title
    const exDisplayProducts = props.data.exdisplay.nodes
    const data = props.data
    const pageContext = props.pageContext
    const handleAddToWishlist = props.handleAddToWishlist

    const updateRoute = (e) => {
      navigate(`/ex-display/${e.target.value}`)
    }

    return (
        <Styling>
          <Helmet>
            <title>{data.page.seo.title}</title>
            <meta name="description" content={data.page.seo.metaDesc} />
            <meta name="og:description" content={data.page.seo.metaDesc} />
            <meta property="og:title" content={data.page.seo.title} />          
            <meta property="og:image" content={'https://morlandbathrooms.com'+data.page.customHeroImage.heroImage.localFile.publicURL} />
          </Helmet>
          <div className="offpage-translate offpage-translate-bg"></div>
          <div className="offpage-translate">
            <Hero image={heroImage} title={title}/>
            {
              pageContext.current === 1 ?
                <IntroSectionReuseable defaultPageFields={pageContext.page.defaultPageFields} />
              : ''
            }
            <div className="filters-outer-wrap">
              <div className="filters-wrap">
                <label htmlFor="filters">Select an ex-display category</label>
                <div className="selectWrapper">
                  <select name="" id="filters" onChange={(e) => {updateRoute(e)}}>
                    <option value="">All categories</option>
                    {data.cats.nodes.map((cat, index) => {
                      return (
                        <option  value={cat.slug}>{cat.title}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="filters-wrap">
                <label htmlFor="filters-condition">Filter by condition</label>
                <div className="selectWrapper">
                  <select name="" id="filters-condition" onChange={(e) => {updateRoute(e)}}>
                    <option value="">All conditions</option>
                    {data.conditions.nodes.map((cat, index) => {
                      return (
                        <option  value={cat.slug}>{cat.name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className={pageContext.current === 1 ? "exdisplay-wrapper" : "exdisplay-wrapper"}>
              
            {
                exDisplayProducts.map((product, index) => {
                    
                    const theImage = getImage(product?.customHeroImage?.heroImage?.localFile)
                    const {manufacturer, price, originalPrice, width, height, depth} = product.exDisplayProductFields
                    const colours = product.colours.nodes
                    
                    
                    return(
                        <Link to={`/ex-display/${product.slug}`} className="exdisplay-product" key={`exdisplay_${index}`} data-title={product.title}>
                          <div className="image-wrapper" >
                            <GatsbyImage image={theImage} className="image" alt={'test'} />                        
                          </div>
                          <div className="product-info">
                            <div className="product-info-inner">
                              <h3 className="article-title">{product.title}</h3>
                              <div className="priceWrapper">
                                <div className="originalPrice">Was: {originalPrice}</div>
                                <div className="price">Now: {price}</div>
                              </div>
                            </div>
                            <span className="button">View product info</span>
                          </div>
                        </Link>
                    )
                })
            }
            </div>
            <Pagination slug={'/ex-display/'} pageContext={pageContext} />
          </div>
     

          <Texture 
          className='texture full transform-offscreen-child' 
          opacity={0.05}  
          position='absolute'
          bottom="0"
          height={''}
          top="0"
          watchScroll={false}
          />
         <CTASection ctaType={'chat'} />
        </Styling>
    )
}

export const query = graphql`
  query($offset: Int!, $postsPerPage: Int!) {
    allExdisplay: allWpExDisplay(sort: {fields: date, order: DESC}) {
      nodes {
        title
        customHeroImage {
          heroImage{
              localFile{
                  childImageSharp {
                      gatsbyImageData(
                      width: 1500
                      placeholder: NONE
                      formats: [AUTO, WEBP]
                      )
                  }
              }
          }
        }
        exDisplayProductFields {
          price
          originalPrice
        }
      }
  }
  global: wp {
    siteSettings {
      acfOptions {
        enquireNowText
        productDisclaimerText
      }
    }
  }
  page: wpPage(title:{eq:"Ex-display"}) {
    seo {
      title
      metaDesc
    }
    customHeroImage {
      heroImage{
          localFile{
            publicURL
          }
      }
    }
  }
  cats:  allWpProduct {
    nodes {
      slug
      title
    }
  }
  conditions:  allWpCondition {
    nodes {
      name
      slug
    }
  }
    exdisplay:  allWpExDisplay(sort: {fields: menuOrder}, filter: {title: {ne: "LEAVE AS 0"}}, skip:$offset, limit: $postsPerPage) {
        nodes {
          title
          slug
          customHeroImage {
            heroImage{
                localFile{
                    childImageSharp {
                        gatsbyImageData(
                        width: 1500
                        placeholder: NONE
                        formats: [AUTO, WEBP]
                        )
                    }
                }
            }
          }
          colours {
            nodes {
              slug
              name
            }
          }
          exDisplayProductFields {
            manufacturer {
              ... on WpBrand {
                title
                slug
              }
            }
            price
            originalPrice
            width
            height
            productDescription
            productCategorySetter
          }
        }
    }
}
`